$gray-100: #FAFAFA;
$gray-200: #E8EAED;
$gray-300: #f2f4f7;
$gray-400: #d0d5dd;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #999999;
$gray-800: #7D7D7D;
$gray-900: #444444;

$blue: #0076D5;
$yellow: #F6B704;
$red: #F87269;
$green: #2FD759;
$black: #1A1A1A;
$cyan: #0DCAF0;

$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$gray: $gray-400;
$gray200: $gray-200;
$gray300: $gray-300;

$theme-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark
);

$custom-theme-colors: (
        'gray': $gray,
        'gray-200': $gray200,
        'gray-300': $gray300,
        'gray-800': $gray-800
);

$enable-gradients: true;
$enable-shadows: true;
// Typography

$font-family-sans-serif: 'Inter', sans-serif !default;
$font-family-base: $font-family-sans-serif;

$border-radius: 8px;
$border-color: $gray-400;
$input-border-radius: 8px;
$btn-border-radius: 8px;
$btn-border-radius-lg: 8px;
$btn-border-width: 0;
$btn-box-shadow: 0;

$input-placeholder-color: $gray-500;

@import '~bootstrap/scss/variables';

$font-family-sans-serif: Poppins, sans-serif;
$font-size-base: 16px;

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$input-padding-y: 16px;
$input-border-radius: 8px;
$input-border-color: $gray-200;
$input-box-shadow: none;
$input-border-width: 1px;
$input-placeholder-color: $gray-700;
$input-font-size: 14px;
$input-font-weight: 400;
$input-disabled-bg: $gray-700;

$form-select-border-radius: 8px;
$form-select-border-color: $gray-200;
$form-select-box-shadow: none;
$form-select-border-width: 1px;
$form-select-placeholder-color: $gray-700;
$form-select-font-size: 14px;
$form-select-font-weight: 400;
$form-select-disabled-bg: $gray-700;
$form-select-padding-y: 16px;


$btn-font-size: 14px;
$btn-font-size-lg: 14px;
$btn-font-weight: 700;
$btn-font-weight-lg: 700;
$btn-padding-x-lg: 40px;
$btn-padding-y-lg: 14px;
$min-contrast-ratio: 2.8;
$btn-border-radius-lg: 100px;
$btn-border-radius: 16px;

$nav-topbar-height: 56px;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-border-radius: 0.25rem;
$breadcrumb-bg: $gray-200;

$card-border-radius: 16px;